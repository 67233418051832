import React, { Component } from "react"
import Layout from "../../components/layout"
import Clutch from "../../components/clutch"

import Igadget_logo from "../../images/igadget/igadget-logo.svg"
import Traffic from "../../images/igadget/traffic.svg"
import Revenue from "../../images/igadget/revenue.svg"
import Transaction from "../../images/igadget/transaction.svg"
import Gadget_client1 from "../../images/igadget/gadget-client1.png"
import Gadget_client2 from "../../images/igadget/gadget-client2.png"


import Scroll_top_img from "../../images/scroll-arrow.png"
import Helmet from "react-helmet"
import "../../styles/includes/_google-shopping-ansel-and-ivy.less";
import { Link } from "gatsby";

import Calltoaction from "../../components/Call-to-action"

export default class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: ''
        }
    }

    componentDidMount() {
        this.setState({
            url: document.location.href
        });
    }
    render() {
        return (
            <Layout>
                <Helmet>
                    <title>Mobile Accessory Store Case Study | Google Shopping Raises Revenue</title>
                    <meta name="description"
                        content="iGadget is a mobile & laptops repairs plus accessory store based in New Zealand. Read our Case Study on how iGadget pulled in 23.59% of total orders via Google Shopping." />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <link rel="canonical" href={this.state.url} />

                    <meta property="og:site_name" content="CueForGood" />
                    <meta property="fb:app_id" content="289086684439915" />
                    <meta property="og:url" content={this.state.url} />
                    <meta property="og:title"
                        content="Mobile Accessory Store Case Study | Google Shopping Raises Revenue" />
                    <meta property="og:description"
                        content="iGadget is a mobile & laptops repairs plus accessory store based in New Zealand. Read our Case Study on how iGadget pulled in 23.59% of total orders via Google Shopping." />
                    <meta property="og:image" content="https://www.cueforgood.com/images/merging-blog.jpg" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:description"
                        content="iGadget is a mobile & laptops repairs plus accessory store based in New Zealand. Read our Case Study on how iGadget pulled in 23.59% of total orders via Google Shopping." />
                    <meta name="twitter:title"
                        content="Mobile Accessory Store Case Study | Google Shopping Raises Revenue" />
                    <meta name="twitter:site" content="@CueForGood" />
                    <meta name="twitter:image" content="https://www.cueforgood.com/images/merging-blog.jpg" />
                </Helmet>
               <div className="cb_expertiseInner cb_igadget">
                  <div className="case_study_page perennialcycle organic-perennial ansel-ivy-googleshopping">

                     <div className="case-wrap  ansel-casewrap">
                           <div className="ww-sec-one ">
                              <div className="container">
                                 <h3 className="cb_igadget-heading">Case Study</h3>
                                 <h1><img style={{ maxWidth: 200 }} src={Igadget_logo} alt="Ansel and Ivy logo" /></h1>
                                 <p className="ansel_p">How CueForGood helped iGadget pull in 23.59% of the total orders through google shopping, making it their 2nd best revenue-generating source.</p>
                                 <p className="igadget-progress-text igadget-progress-text-lowercase">Google shopping helped iGadget earn</p>

                                 <div className="revenue-perennial">
                                    <div className="revenue-inn">
                                       <div className="revenue-single circle1">
                                   <div className="revenue-single-outer">
                                         <div className="progress yellow1">
                                            
                                            <span className="progress-right">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <span className="progress-right hiddenprogress">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <div className="progress-value"> 23.59%</div>
                                         </div>
                                      </div>
                                      <p>Of total <br/>orders</p>
                                       </div>
                                    
                                         <div className="revenue-single circle1">
                                   <div className="revenue-single-outer">
                                         <div className="progress yellow2">
                                            
                                            <span className="progress-right">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <span className="progress-right hiddenprogress">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <div className="progress-value"> 26.09%</div>
                                         </div>
                                      </div>
                                      <p>Of total <br/>clicks</p>
                                       </div>
                                           <div className="revenue-single circle1">
                                   <div className="revenue-single-outer">
                                         <div className="progress yellow3">
                                            
                                            <span className="progress-right">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <span className="progress-right hiddenprogress">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <div className="progress-value"> 19.81%</div>
                                         </div>
                                      </div>
                                      <p>Of total <br/>revenue</p>
                                       </div>
                                    </div>
                                 </div>
                             
                             </div>
                           </div>
                     </div>

                     

                     <div className="google-shop">
                         <div className="container">
                              <p>For iGadget, Google shopping is now the</p>
                              <ul>
                                 <li>
                                    <span><img src={Traffic} alt="2rd Best Traffic Generating Platform " /></span>
                                    <h4>2nd best <br/>traffic generating<br/>platform </h4>
                                 </li>
                                  <li>
                                    <span><img src={Revenue} alt="2rd Best Revenue Generating Platform  " /></span>
                                    <h4>2nd best <br/>revenue generating<br/>platform </h4>
                                 </li>
                                 <li>
                                    <span><img src={Transaction} alt="2rd Best Transaction Generatio Platform  " /></span>
                                    <h4>2nd best <br/>transaction generating<br/>platform </h4>
                                 </li>
                              </ul>
                            </div>
                     </div>
                      <div className="about-client-Ansel about-client-igadget">
                         <div className="container">
                              <p className="note"><strong>Note:</strong> Measuring the performance from 25th November 2020 to July 2022</p>
                            <div className="about-inn">
                              <div className="about-left">
                                 <h2 className="heading_main">About the client</h2>
                                 <p>iGadget is an eCommerce shop that offers an extensive range of mobiles, laptops, and tablet repair parts along with their accessories; available throughout New Zealand.</p>
                              </div>
                              <div className="about-right">
                                 <img className="about-client-igadget-img1" src={Gadget_client1} alt="About the Client  " />
                                 <img className="about-client-igadget-img2" src={Gadget_client2} alt="About the Client  " />
                              </div>
                            </div>
                            </div>
                     </div>
    
                     <div className="cb_good_section call-action-component">
                        <Calltoaction sid="ansel1"/>
                     </div>

                    <div className="Anselcasestudy-background">
                       <div className="container">
                           <h2 className="heading_main">Background</h2>
                             <p>We had been helping iGadget with their website design and development when the client discussed their GMC and google Ads account suspension. Not just this, the client also shared their concern regarding the high CPC rate. While we got the GMC account approved on 16 October 2020 and the google Ads account on 19 November 2020, we started working on the campaigns only from 24 November 2020 onwards.</p>
                       </div>
                    </div>

                    <div className="Challenges  anselivy-challenges">
                        <div className="container">
                           <h2 className="heading_main">Challenges</h2>
                           <div className="out-Challenges">
                           <div className="inn-Challenges">
                               <h2 className="heading_count">1</h2>
                               <h3>Enabling suspended GMC account</h3>
                              <p>Before approaching us, the client tried setting up the GMC account in October 2020. Due to certain areas being neglected, the account got suspended for misinterpretation of products. Google is pretty strict when it comes to accounts violating the Acceptable Business Policy which made getting the account approved a challenge. Nonetheless, the experts in our team delved deep to understand and eradicate the shortcomings.</p>
                           </div>
                           <div className="inn-Challenges">
                               <h2 className="heading_count">2</h2>
                               <h3>Enabling suspended google Ads account</h3>
                              <p>Even though the client tried setting up iGadget’s GA account, something was amiss that led to the account getting suspended. We understand that every suspension is unique and that it requires thorough investigation. Therefore, fixing the same was a sweet challenge for us. </p>
                           </div>
                          
                       
                           <div className="inn-Challenges">
                               <h2 className="heading_count">3</h2>
                               <h3>Reducing the high cost per click</h3>
                              <p>The Shopping Campaign being run by the client wasn’t performing well. It was set up through Shopify and the CPC was way too high - $5.50 per click. Putting together a campaign that could give the best ROI was another sweet challenge.</p>
                           </div>

                           <div className="inn-Challenges">
                               <h2 className="heading_count">4</h2>
                               <h3>Optimizing product feed</h3>
                              <p>Because the product feed is generated via API (automated feed), there are fewer chances of optimizing it. However, it is important to gain better results. </p>
                           </div>
                           
                           <div className="inn-Challenges">
                               <h2 className="heading_count">5</h2>
                               <h3>Setting up smart shopping campaign</h3>
                              <p>The client was keen on running a Smart Shopping Campaign but the account wasn’t eligible for the same. That’s because the Display Network must have 50 conversions and the Search Network must have 100 conversions before Smart Shopping can be explored. </p>
                           </div>
                           
                          
                           </div>
                           
                        </div>
                     </div>

                     {/* <div className="Anselcasestudy-whatwedid">
                       <div className="container">
                                   <h2 className="heading_main">What We Did</h2>
                                <p>We ensured that the products offered by Ansel & Ivy appeared</p>
                                 <ul>
                                    <li>
                                       <span className="icon"> <img src={Icon1} alt="At the right place" /></span>
                                       <p>At the right place</p>
                                    </li>
                                    <li>
                                       <span className="icon"> <img src={Icon2} alt="At the right time" /></span>
                                       <p>At the right time</p>
                                    </li>
                                    <li>
                                       <span className="icon"> <img src={Icon3} alt="For the right customers" /></span>
                                       <p>For the right customers</p>
                                    </li>
                                 </ul>

                       </div>
                    </div> */}

                  <div className="Challenges  anselivy-challenges how-ansel igadget-Approach">
                        <div className="container">
                           <h2 className="heading_main">Approach</h2>
                           <div className="out-Challenges">
                           <div className="inn-Challenges">
                               <h2 className="heading_count">1</h2>
                               <h3>Addressed the GMC suspension</h3>
                              <p>Google leaves no stone unturned when it comes to helping users make informed decisions. It only puts up ads that are honest, straightforward, and clear regarding the product/service being offered. We addressed the issue by making certain changes and contacted Google to review the account. iGadget’s GMC account now stands approved.</p>
                           </div>
                           <div className="inn-Challenges">
                               <h2 className="heading_count">2</h2>
                               <h3>Addressed the Ads account suspension</h3>
                              <p>We dug deep and realized the lack of information as the reason behind the Google Ads Account suspension. After adding the required info and requesting Google to review the account, it was reactivated.</p>
                           </div>
                          
                       
                           <div className="inn-Challenges">
                               <h2 className="heading_count">3</h2>
                               <h3>Optimizing the shopping campaign</h3>
                              <p>We set up the shopping campaign through the Google Ads account and kept optimizing it to bring about the best results. From $5.50 as the CPC, we brought it down to less than $0.3.</p>
                           </div>
                             <div className="inn-Challenges">
                               <h2 className="heading_count">4</h2>
                               <h3>Scanning for opportunities to optimize the product feed</h3>
                              <p>We looked for ways to optimize the feed; all the while staying well within the boundaries drawn by GMC regarding its policies. After the implementation, we were able to generate a positive ROI for the client.</p>
                           </div>
                             <div className="inn-Challenges">
                               <h2 className="heading_count">5</h2>
                               <h3>Started with standard shopping campaign</h3>
                              <p>We suggested and started with a Standard Shopping campaign to drive more traffic and sales. Once we fulfilled the requirements and Google had adequate data on who the ideal customers were, we switched to Smart Shopping. Since then, it has been giving us great results.</p>
                           </div>
                          
                           </div>
                           
                        </div>
                     </div>

                     <div className="cb_good_section call-action-component">
                        <div className="service_weoffer cb_testimonails">
                        <Calltoaction sid="ansel2"/>
                        </div>
                     </div>
             
                  <div className="case-wrap  ansel-casewrap result-ansel result-igadget2">
                           <div className="ww-sec-one ">
                              <div className="container">
                                      <h2 className="heading_main">Results</h2>
                                      <h6>Google shopping has helped iGadget acquire</h6>
                                 <div className="revenue-perennial">
                                    <div className="revenue-inn">
                                       <div className="revenue-single circle1">
                                   <div className="revenue-single-outer">
                                         <div className="progress yellow1">
                                            
                                            <span className="progress-right">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <span className="progress-right hiddenprogress">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <div className="progress-value">23.59%</div>
                                         </div>
                                      </div>
                                      <p>Of the total <br/>orders</p>
                                       </div>
                                    
                                         <div className="revenue-single circle1">
                                   <div className="revenue-single-outer">
                                         <div className="progress yellow2">
                                            
                                            <span className="progress-right">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <span className="progress-right hiddenprogress">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <div className="progress-value">26.09%</div>
                                         </div>
                                      </div>
                                      <p>Of the total <br/>click</p>
                                       </div>
                                           <div className="revenue-single circle1">
                                   <div className="revenue-single-outer">
                                         <div className="progress yellow3">
                                            
                                            <span className="progress-right">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <span className="progress-right hiddenprogress">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <div className="progress-value">19.81%</div>
                                         </div>
                                      </div>
                                      <p>Of the total <br/>revenue</p>
                                       </div>
                                    </div>
                                 </div>
                             
                             </div>
                           </div>
                     </div>
                     {/* <div className="google-shop">
                         <div className="container">
                              <p>Google Shopping Is now the</p>
                              <ul>
                                 <li>
                                    <span><img src={Traffic} alt="2rd Best Traffic Generating Platform " /></span>
                                    <h4>2nd Best <br/>Traffic Generating<br/>Platform </h4>
                                 </li>
                                  <li>
                                    <span><img src={Revenue} alt="2rd Best Revenue Generating Platform  " /></span>
                                    <h4>2nd Best <br/>Revenue Generating<br/>Platform </h4>
                                 </li>
                                 <li>
                                    <span><img src={Transaction} alt="2rd Best Transaction Generatio Platform  " /></span>
                                    <h4>2nd Best <br/>Transaction Generating<br/>Platform </h4>
                                 </li>
                              </ul>
                           </div>
                     </div> */}

                       <div className="footer-atg footer-ansel igadget-footer-atg">
                        <div className="container">
                           <p className="footer-igadget-text">All this has been achieved in less than a year, precisely months.</p>
                        <p>Want to make more sales using google shopping Ads? Get in touch with at <a href="mailto:letstalk@cueforgood.com">letstalk@cueforgood.com</a> to discover strategies that directly align with your business goals.</p>
                               {/* <img src={Ansel_Btm} alt="Ansel and Ivy" /> */}
                        </div>
                     </div>  

                 
               

                    <div id="scroll-to" className="case-scroll">
                       <a href="#" title="Scroll To Top"><img src={Scroll_top_img} alt="Scroll To Top" /></a>
                    </div>
                  </div>
               </div>

                <Clutch />

            </Layout>
        )
    }
}